/**
 * Automatically Generated with Rails using {@link CompanySetting.template}
 * see {@link lib_development/generators/company_settings/company_settings_generator.rb} for details
 * run `rails g company_settings -f` to regenerate
 */

import * as React from 'react';

/**
 * All the possible company settings that can be used within the application
 */
export type CompanySettingsKeys =
  | 'accessible_date_and_phone'
  | 'add_cc_and_bcc_to_email'
  | 'additional_permissions'
  | 'additional_statuses'
  | 'advanced_reporting'
  | 'advanced_search'
  | 'all_candidates_v2_filters'
  | 'allow_bulk_pool'
  | 'allow_cover_letters'
  | 'allow_creating_company_teams'
  | 'allow_editing_job_requisition_fields'
  | 'allow_inactive_structures'
  | 'allow_mydata_deletion'
  | 'allowed_email_suffixes_only_for_internal_jobs'
  | 'alternative_saml_claim_identifier'
  | 'anonymous_screening'
  | 'application_national_identifiers'
  | 'approval_lists'
  | 'approval_workflow_logging'
  | 'auto_translate'
  | 'automatically_reject_referrals'
  | 'basic_consent_management'
  | 'bulk_csv_editing'
  | 'bulk_download'
  | 'bulk_email_job_seekers'
  | 'can_skip_sign_off_stage'
  | 'candidate_bulk_actions'
  | 'candidate_custom_fields'
  | 'candidate_details_in_offer_header'
  | 'candidate_job_preferences'
  | 'candidate_policy_plucking'
  | 'candidate_surveys'
  | 'candidate_viewer_role'
  | 'careers_site_builder_v2'
  | 'careers_site_form_captchas'
  | 'conditional_custom_fields'
  | 'create_requisitions_for_existing_jobs'
  | 'cronofy_enterprise_connect'
  | 'custom_application_received_templates'
  | 'custom_field_option_labels'
  | 'custom_job_posting_locations'
  | 'dei_reports'
  | 'disable_cookie_dialog'
  | 'disable_indeed_feed'
  | 'disable_public_hiring_profiles'
  | 'docusign_logging'
  | 'download_pdf_application'
  | 'edit_offer_before_send'
  | 'edit_sent_offers'
  | 'edit_sent_offers_all_fields'
  | 'enable_cronofy_event_deletion_tracking'
  | 'enable_redaction'
  | 'enforce_all_offer_fields_required'
  | 'enforce_all_offer_salary_field'
  | 'essential_cookies_only'
  | 'external_recruiters'
  | 'extra_monitoring_for_automations'
  | 'facebook_advert'
  | 'filled_tab'
  | 'group_assessments'
  | 'group_interviews'
  | 'grouped_jobs'
  | 'hide_discarded_eq_templates_in_reports'
  | 'hide_referral_applications'
  | 'indeed_feed_stages'
  | 'indeed_locale_url'
  | 'indeed_theme_sourcename'
  | 'integration_plugins'
  | 'internship_programme'
  | 'job_requisition_management'
  | 'limit_onboarding_to_hired_candidates'
  | 'linkedin_job_postings'
  | 'linkedin_rsc'
  | 'location_city_state'
  | 'manage_communication_preferences'
  | 'manually_send_offers'
  | 'master_job_applications_view'
  | 'max_applications'
  | 'monitor_saml'
  | 'monitor_with_rum'
  | 'move_to_any_job'
  | 'move_to_hired_on_offer_acceptance'
  | 'multiple_company_themes'
  | 'multiple_job_postings'
  | 'offer_management'
  | 'offer_retracted_notification'
  | 'offer_splitting'
  | 'onboarding_application_resource_plucking'
  | 'onboarding_dashboard_stage_step_ownership'
  | 'onboarding_v1'
  | 'onboarding_v2'
  | 'onboarding_v2_enforce_job_visibilities'
  | 'onboarding_v2_turbocharger'
  | 'personal_details_auto_update'
  | 'position_management'
  | 'postmark_event_monitoring'
  | 'premium_workflow_automations'
  | 'private_calendar_events'
  | 'radius_search'
  | 'react_select_on_external_jobs'
  | 'recruitment_agencies'
  | 'recruitment_agency_location_matching'
  | 'redirect_moved_job_to_copied_object'
  | 'redirect_to_copied_object'
  | 'redirect_to_custom_subdomain'
  | 'reference_checking'
  | 'referral_management'
  | 'reject_all_other_applications'
  | 'remove_email_footer'
  | 'remove_logo_from_email_template'
  | 'report_only_csp'
  | 'require_headcount'
  | 'restricted_cronofy_oauth_scope'
  | 'resume_parsing'
  | 'scorecard_reporting'
  | 'scorecard_summary'
  | 'semi_private_comments'
  | 'send_pdf_copy_of_application'
  | 'show_all_candidates_index'
  | 'show_compensation_by_default'
  | 'show_date_in_offer_header'
  | 'show_job_seekers_in_all_candidates'
  | 'skills'
  | 'stage_actions'
  | 'stage_dates_in_external_application'
  | 'stage_visibilities'
  | 'strict_ofccp_compliance'
  | 'structured_sections'
  | 'talent_pipeline'
  | 'talent_pipeline_v2'
  | 'task_management'
  | 'team_rejection_notification'
  | 'two_column_sidebar'
  | 'use_cached_application_ids'
  | 'use_company_from_address'
  | 'use_fallback_sign_off_workflows'
  | 'variable_sign_off_signatories'
  | 'visible_other_applications'
  | 'vonq_v2'
  | 'workflow_automation'
  | 'workflow_automation_v2';

/**
 * The shape of our CompanySettings object, narrowed down to the available keys and values only.
 */
export interface ICompanySettings {
  /**
   * Additional application form accessibility
   */
  accessible_date_and_phone: boolean;
  /**
   * Add CC and BCC to Messages from Pinpoint
   */
  add_cc_and_bcc_to_email: boolean;
  /**
   * Additional permissions
   */
  additional_permissions: boolean;
  /**
   * Add hold and cancelled as additional statuses
   */
  additional_statuses: boolean;
  /**
   * Advanced Reporting
   */
  advanced_reporting: boolean;
  /**
   * Advanced search
   */
  advanced_search: boolean;
  /**
   * All candidates v2 filters
   */
  all_candidates_v2_filters: boolean;
  /**
   * Allow bulk pooling
   */
  allow_bulk_pool: boolean;
  /**
   * Allow requesting cover letters
   */
  allow_cover_letters: boolean;
  /**
   * Allow companies to create company teams
   */
  allow_creating_company_teams: boolean;
  /**
   * Allow editing fields on a job that were set by the requisition
   */
  allow_editing_job_requisition_fields: boolean;
  /**
   * Allow for structures to be toggled as inactive
   */
  allow_inactive_structures: boolean;
  /**
   * Allow candidates to delete their own data
   */
  allow_mydata_deletion: boolean;
  /**
   * Validate email address on internal job applicants
   */
  allowed_email_suffixes_only_for_internal_jobs: boolean;
  /**
   * Alternative SAML claim identifier
   */
  alternative_saml_claim_identifier: boolean;
  /**
   * Anonymous Screening
   */
  anonymous_screening: boolean;
  /**
   * Add national identifiers to applications and allow collecting it
   */
  application_national_identifiers: boolean;
  /**
   * Approval lists
   */
  approval_lists: boolean;
  /**
   * Approval workflow logging
   */
  approval_workflow_logging: boolean;
  /**
   * Auto translate
   */
  auto_translate: boolean;
  /**
   * Automatically reject a referral when the application is rejected
   */
  automatically_reject_referrals: boolean;
  /**
   * Basic consent management
   */
  basic_consent_management: boolean;
  /**
   * Bulk CSV editing
   */
  bulk_csv_editing: boolean;
  /**
   * Allow bulk download of applications in CSV format from the table actions
   */
  bulk_download: boolean;
  /**
   * Allow bulk emailing job seekers
   */
  bulk_email_job_seekers: boolean;
  /**
   * Can skip sign-off stage
   */
  can_skip_sign_off_stage: boolean;
  /**
   * Candidate bulk actions
   */
  candidate_bulk_actions: boolean;
  /**
   * Candidate custom fields
   */
  candidate_custom_fields: boolean;
  /**
   * Show the candidate name and address in the header on the offer letters
   */
  candidate_details_in_offer_header: boolean;
  /**
   * Candidate job preferences
   */
  candidate_job_preferences: boolean;
  /**
   * Candidate Policy Plucking
   */
  candidate_policy_plucking: boolean;
  /**
   * Candidate surveys
   */
  candidate_surveys: boolean;
  /**
   * Can view all candidate records
   */
  candidate_viewer_role: boolean;
  /**
   * Careers Site Builder V2 - UNDER DEVELOPMENT DO NOT USE
   */
  careers_site_builder_v2: boolean;
  /**
   * Use CAPTCHAs in Careers Site forms
   */
  careers_site_form_captchas: boolean;
  /**
   * Conditional custom fields
   */
  conditional_custom_fields: boolean;
  /**
   * Enable creating requisitions for existing job
   */
  create_requisitions_for_existing_jobs: boolean;
  /**
   * Enable Cronofy enterprise connect
   */
  cronofy_enterprise_connect: boolean;
  /**
   * Custom application received templates
   */
  custom_application_received_templates: boolean;
  /**
   * Enable custom field option labels
   */
  custom_field_option_labels: boolean;
  /**
   * Allow custom job posting locations
   */
  custom_job_posting_locations: boolean;
  /**
   * Allow creating DEI reports
   */
  dei_reports: boolean;
  /**
   * Disable all Pinpoint cookie dialogs
   */
  disable_cookie_dialog: boolean;
  /**
   * Disable the indeed feed for this company
   */
  disable_indeed_feed: boolean;
  /**
   * Disable public hiring profiles
   */
  disable_public_hiring_profiles: boolean;
  /**
   * Extra monitoring for docusign envelopes
   */
  docusign_logging: boolean;
  /**
   * Allow downloading applications as a PDF
   */
  download_pdf_application: boolean;
  /**
   * Editable offer letter at time of creation
   */
  edit_offer_before_send: boolean;
  /**
   * Can modify sent offer date fields and resend the offer
   */
  edit_sent_offers: boolean;
  /**
   * Can edit sent offers
   */
  edit_sent_offers_all_fields: boolean;
  /**
   * Event deletion notifications
   */
  enable_cronofy_event_deletion_tracking: boolean;
  /**
   * Enable redaction of anonymous screening roles
   */
  enable_redaction: boolean;
  /**
   * Require completion of all offer fields
   */
  enforce_all_offer_fields_required: boolean;
  /**
   * Enforce offer salary fields
   */
  enforce_all_offer_salary_field: boolean;
  /**
   * Enable essential cookies only
   */
  essential_cookies_only: boolean;
  /**
   * External Recruiters
   */
  external_recruiters: boolean;
  /**
   * Extra monitoring for automations
   */
  extra_monitoring_for_automations: boolean;
  /**
   * Facebook advert creation
   */
  facebook_advert: boolean;
  /**
   * Filled tab
   */
  filled_tab: boolean;
  /**
   * Group assessments
   */
  group_assessments: boolean;
  /**
   * Enable group interviews
   */
  group_interviews: boolean;
  /**
   * Allow grouping jobs
   */
  grouped_jobs: boolean;
  /**
   * Report on discarded equality monitoring templates
   */
  hide_discarded_eq_templates_in_reports: boolean;
  /**
   * Hide referred applications
   */
  hide_referral_applications: boolean;
  /**
   * Add the external stages to the indeed feed job description
   */
  indeed_feed_stages: boolean;
  /**
   * Use the locale in the indeed URL
   */
  indeed_locale_url: boolean;
  /**
   * Indeed theme sourcename
   */
  indeed_theme_sourcename: boolean;
  /**
   * Integration plugins
   */
  integration_plugins: boolean;
  /**
   * Change the platform for 10,000 interns programme
   */
  internship_programme: boolean;
  /**
   * Enable job requisition management
   */
  job_requisition_management: boolean;
  /**
   * Limit sending applicants to onboarding to only those in hired
   */
  limit_onboarding_to_hired_candidates: boolean;
  /**
   * LinkedIn job postings
   */
  linkedin_job_postings: boolean;
  /**
   * LinkedIn Recruiter System Connect
   */
  linkedin_rsc: boolean;
  /**
   * Use city, state as the location name externally
   */
  location_city_state: boolean;
  /**
   * Basic communication preference management
   */
  manage_communication_preferences: boolean;
  /**
   * Allow approved offers to be edited before sending to candidates
   */
  manually_send_offers: boolean;
  /**
   * Master job applications view
   */
  master_job_applications_view: boolean;
  /**
   * Limit the number of applications per candidate
   */
  max_applications: boolean;
  /**
   * Monitor SAML logins and attempts
   */
  monitor_saml: boolean;
  /**
   * Monitor logged in users with realtime user monitoring
   */
  monitor_with_rum: boolean;
  /**
   * Allow moving an application to any job
   */
  move_to_any_job: boolean;
  /**
   * Move applications to hired once an offer is accepted
   */
  move_to_hired_on_offer_acceptance: boolean;
  /**
   * Enable the ability to create and manage multiple themes for this company
   */
  multiple_company_themes: boolean;
  /**
   * Allow creating multiple job postings per job
   */
  multiple_job_postings: boolean;
  /**
   * Offer Management
   */
  offer_management: boolean;
  /**
   * Send offer retraction notification
   */
  offer_retracted_notification: boolean;
  /**
   * Offer splitting
   */
  offer_splitting: boolean;
  /**
   * Use pluck in onboarding application resource
   */
  onboarding_application_resource_plucking: boolean;
  /**
   * Onboarding Dashboard and stage / step ownership
   */
  onboarding_dashboard_stage_step_ownership: boolean;
  /**
   * Onboarding V1
   */
  onboarding_v1: boolean;
  /**
   * Onboarding V2
   */
  onboarding_v2: boolean;
  /**
   * Onboarding V2 enforce job visibilities
   */
  onboarding_v2_enforce_job_visibilities: boolean;
  /**
   * Onboarding V2 performance enhancements
   */
  onboarding_v2_turbocharger: boolean;
  /**
   * Personal details auto update
   */
  personal_details_auto_update: boolean;
  /**
   * Position Management
   */
  position_management: boolean;
  /**
   * Postmark event monitoring
   */
  postmark_event_monitoring: boolean;
  /**
   * Premium features for Automations
   */
  premium_workflow_automations: boolean;
  /**
   * Add calendar events as private
   */
  private_calendar_events: boolean;
  /**
   * Radius search
   */
  radius_search: boolean;
  /**
   * Use the react select on the external job index pages. Added for accessibility purposes should not be toggled on
   */
  react_select_on_external_jobs: boolean;
  /**
   * Recruitment Agencies
   */
  recruitment_agencies: boolean;
  /**
   * Recruitment Agency Location Matching
   */
  recruitment_agency_location_matching: boolean;
  /**
   * Redirect job to copied object
   */
  redirect_moved_job_to_copied_object: boolean;
  /**
   * Redirect to copied object
   */
  redirect_to_copied_object: boolean;
  /**
   * Redirect all careers site requests to custom subdomain
   */
  redirect_to_custom_subdomain: boolean;
  /**
   * Reference checking
   */
  reference_checking: boolean;
  /**
   * Referral Management
   */
  referral_management: boolean;
  /**
   * Reject all other applications
   */
  reject_all_other_applications: boolean;
  /**
   * Remove email footer
   */
  remove_email_footer: boolean;
  /**
   * Remove the company logo from the email template
   */
  remove_logo_from_email_template: boolean;
  /**
   * Switch CSP policy to report only for company careers site
   */
  report_only_csp: boolean;
  /**
   * Require headcount on job creation
   */
  require_headcount: boolean;
  /**
   * Limit the Cronofy scope to just read free busy. Please do not turn this on without confirming with the engineering team
   */
  restricted_cronofy_oauth_scope: boolean;
  /**
   * Resume parsing
   */
  resume_parsing: boolean;
  /**
   * Scorecard reporting
   */
  scorecard_reporting: boolean;
  /**
   * Scorecard Summary
   */
  scorecard_summary: boolean;
  /**
   * Allow company managers to view private comments
   */
  semi_private_comments: boolean;
  /**
   * Allow candidates to receive a pdf copy of their details when applying
   */
  send_pdf_copy_of_application: boolean;
  /**
   * Master all candidates view
   */
  show_all_candidates_index: boolean;
  /**
   * Show compensation by default
   */
  show_compensation_by_default: boolean;
  /**
   * Show the date in the header on the offer letters
   */
  show_date_in_offer_header: boolean;
  /**
   * Show job seekers in the all candidates view
   */
  show_job_seekers_in_all_candidates: boolean;
  /**
   * Allow managing and filtering on skills
   */
  skills: boolean;
  /**
   * Stage actions
   */
  stage_actions: boolean;
  /**
   * Stage group movement dates into the API
   */
  stage_dates_in_external_application: boolean;
  /**
   * Can assign visibilities to stages
   */
  stage_visibilities: boolean;
  /**
   * Restrict any action that does not comply with OFCCP
   */
  strict_ofccp_compliance: boolean;
  /**
   * Structured sections
   */
  structured_sections: boolean;
  /**
   * Enable the talent pipeline
   */
  talent_pipeline: boolean;
  /**
   * Talent Pipeline V2
   */
  talent_pipeline_v2: boolean;
  /**
   * Task Management
   */
  task_management: boolean;
  /**
   * Send sign off rejection notifications to anyone with visibility
   */
  team_rejection_notification: boolean;
  /**
   * Use the two column sidebar navigation panel
   */
  two_column_sidebar: boolean;
  /**
   * Use cached application ids
   */
  use_cached_application_ids: boolean;
  /**
   * Use company@pinpoint.email instead of uuid@pinpoint.email when sending emails
   */
  use_company_from_address: boolean;
  /**
   * Use fallback workflows when approvable resources have no matching sign-off workflows
   */
  use_fallback_sign_off_workflows: boolean;
  /**
   * Approval workflows v2
   */
  variable_sign_off_signatories: boolean;
  /**
   * Show other applications regardless of visibilities
   */
  visible_other_applications: boolean;
  /**
   * Use the Vonq V2 API
   */
  vonq_v2: boolean;
  /**
   * Enable job automations
   */
  workflow_automation: boolean;
  /**
   * Automations
   */
  workflow_automation_v2: boolean;
}

export interface IAccessibleDateAndPhoneTemplate {
  default_value: false;
  description: "If toggled on, phone and date fields will use simple html elements and we add an asterisk to required questions";
  name: 'Additional application form accessibility';
  link: '';
  setting_key: 'accessible_date_and_phone';
  values: [true, false];
}
export interface IAddCcAndBccToEmailTemplate {
  default_value: false;
  description: "If toggled on, certain messages sent from within Pinpoint will allow CC and BCC addresses to be added.";
  name: 'Add CC and BCC to Messages from Pinpoint';
  link: '';
  setting_key: 'add_cc_and_bcc_to_email';
  values: [true, false];
}
export interface IAdditionalPermissionsTemplate {
  default_value: false;
  description: "If toggled on, we will allow (and enforce) the use of additional permissions for users";
  name: 'Additional permissions';
  link: '';
  setting_key: 'additional_permissions';
  values: [true, false];
}
export interface IAdditionalStatusesTemplate {
  default_value: false;
  description: "";
  name: 'Add hold and cancelled as additional statuses';
  link: '';
  setting_key: 'additional_statuses';
  values: [true, false];
}
export interface IAdvancedReportingTemplate {
  default_value: false;
  description: "If toggled on, clients will have access to the Insights Hub and Report Builder";
  name: 'Advanced Reporting';
  link: '';
  setting_key: 'advanced_reporting';
  values: [true, false];
}
export interface IAdvancedSearchTemplate {
  default_value: false;
  description: "If the company was created before November 2021 then speak to the CTO as search indices will need to be updated before this can be enabled";
  name: 'Advanced search';
  link: '';
  setting_key: 'advanced_search';
  values: [true, false];
}
export interface IAllCandidatesV2FiltersTemplate {
  default_value: false;
  description: "If toggled on, will use new filtering side panel on the all candidates index";
  name: 'All candidates v2 filters';
  link: '';
  setting_key: 'all_candidates_v2_filters';
  values: [true, false];
}
export interface IAllowBulkPoolTemplate {
  default_value: false;
  description: "If toggled on, users will be able to bulk pool candidates. This is only shown when 10,000 interns programme setting is toggled on.";
  name: 'Allow bulk pooling';
  link: '';
  setting_key: 'allow_bulk_pool';
  values: [true, false];
}
export interface IAllowCoverLettersTemplate {
  default_value: false;
  description: "If toggled on, you can request a cover letter on the application form";
  name: 'Allow requesting cover letters';
  link: '';
  setting_key: 'allow_cover_letters';
  values: [true, false];
}
export interface IAllowCreatingCompanyTeamsTemplate {
  default_value: false;
  description: "If toggled on, companies will be able to create teams that exist outside of the normal visibilities restrictions.";
  name: 'Allow companies to create company teams';
  link: '';
  setting_key: 'allow_creating_company_teams';
  values: [true, false];
}
export interface IAllowEditingJobRequisitionFieldsTemplate {
  default_value: false;
  description: "";
  name: 'Allow editing fields on a job that were set by the requisition';
  link: '';
  setting_key: 'allow_editing_job_requisition_fields';
  values: [true, false];
}
export interface IAllowInactiveStructuresTemplate {
  default_value: false;
  description: "";
  name: 'Allow for structures to be toggled as inactive';
  link: '';
  setting_key: 'allow_inactive_structures';
  values: [true, false];
}
export interface IAllowMydataDeletionTemplate {
  default_value: true;
  description: "If toggled on, we will allow candidates to delete their personal information using the Manage My Data tool";
  name: 'Allow candidates to delete their own data';
  link: '';
  setting_key: 'allow_mydata_deletion';
  values: [true, false];
}
export interface IAllowedEmailSuffixesOnlyForInternalJobsTemplate {
  default_value: false;
  description: "If toggled on, candidates applying to internal jobs using the external application form will have to supply an email address from an allowed internal email domain";
  name: 'Validate email address on internal job applicants';
  link: '';
  setting_key: 'allowed_email_suffixes_only_for_internal_jobs';
  values: [true, false];
}
export interface IAlternativeSamlClaimIdentifierTemplate {
  default_value: false;
  description: "If toggled on, we will allow the use of an alternative SAML claim identifier for logging into the platform";
  name: 'Alternative SAML claim identifier';
  link: '';
  setting_key: 'alternative_saml_claim_identifier';
  values: [true, false];
}
export interface IAnonymousScreeningTemplate {
  default_value: true;
  description: "If toggled on, clients will be able to turn anonymous screening on for jobs";
  name: 'Anonymous Screening';
  link: '';
  setting_key: 'anonymous_screening';
  values: [true, false];
}
export interface IApplicationNationalIdentifiersTemplate {
  default_value: false;
  description: "";
  name: 'Add national identifiers to applications and allow collecting it';
  link: '';
  setting_key: 'application_national_identifiers';
  values: [true, false];
}
export interface IApprovalListsTemplate {
  default_value: false;
  description: "If toggled on, users will be able to create approval lists for sign-off workflows";
  name: 'Approval lists';
  link: '';
  setting_key: 'approval_lists';
  values: [true, false];
}
export interface IApprovalWorkflowLoggingTemplate {
  default_value: false;
  description: "If toggled on, we will log each time the an approval is requested";
  name: 'Approval workflow logging';
  link: '';
  setting_key: 'approval_workflow_logging';
  values: [true, false];
}
export interface IAutoTranslateTemplate {
  default_value: false;
  description: "If toggled on, we will automatically use AI to translate user generated content. This currently only works for questions";
  name: 'Auto translate';
  link: '';
  setting_key: 'auto_translate';
  values: [true, false];
}
export interface IAutomaticallyRejectReferralsTemplate {
  default_value: false;
  description: "";
  name: 'Automatically reject a referral when the application is rejected';
  link: '';
  setting_key: 'automatically_reject_referrals';
  values: [true, false];
}
export interface IBasicConsentManagementTemplate {
  default_value: false;
  description: "If toggled on, applicants will have the option of allowing data to be used for one job or all jobs";
  name: 'Basic consent management';
  link: '';
  setting_key: 'basic_consent_management';
  values: [true, false];
}
export interface IBulkCsvEditingTemplate {
  default_value: false;
  description: "If toggled on, users will be able to bulk edit jobs and positions using a CSV upload";
  name: 'Bulk CSV editing';
  link: '';
  setting_key: 'bulk_csv_editing';
  values: [true, false];
}
export interface IBulkDownloadTemplate {
  default_value: false;
  description: "This was only created for the 10kbi project and should not be enabled for anyone";
  name: 'Allow bulk download of applications in CSV format from the table actions';
  link: '';
  setting_key: 'bulk_download';
  values: [true, false];
}
export interface IBulkEmailJobSeekersTemplate {
  default_value: false;
  description: "This was only created for the 10kbi project and should not be enabled for anyone";
  name: 'Allow bulk emailing job seekers';
  link: '';
  setting_key: 'bulk_email_job_seekers';
  values: [true, false];
}
export interface ICanSkipSignOffStageTemplate {
  default_value: false;
  description: "If toggled on, users will be able to skip the next sign-off stage instead of defining the next stages approvers";
  name: 'Can skip sign-off stage';
  link: '';
  setting_key: 'can_skip_sign_off_stage';
  values: [true, false];
}
export interface ICandidateBulkActionsTemplate {
  default_value: false;
  description: "If toggled on, users will be able to perform bulk actions on candidates";
  name: 'Candidate bulk actions';
  link: '';
  setting_key: 'candidate_bulk_actions';
  values: [true, false];
}
export interface ICandidateCustomFieldsTemplate {
  default_value: false;
  description: "If toggled on, users will be able to create custom fields for candidates";
  name: 'Candidate custom fields';
  link: '';
  setting_key: 'candidate_custom_fields';
  values: [true, false];
}
export interface ICandidateDetailsInOfferHeaderTemplate {
  default_value: false;
  description: "If toggled on, we will show the candidate name and address in the header on the offer letters";
  name: 'Show the candidate name and address in the header on the offer letters';
  link: '';
  setting_key: 'candidate_details_in_offer_header';
  values: [true, false];
}
export interface ICandidateJobPreferencesTemplate {
  default_value: false;
  description: "Allow applicants to choose a 2nd and 3rd job preference when applying";
  name: 'Candidate job preferences';
  link: '';
  setting_key: 'candidate_job_preferences';
  values: [true, false];
}
export interface ICandidatePolicyPluckingTemplate {
  default_value: false;
  description: "Do not change this toggle, for development purposes only";
  name: 'Candidate Policy Plucking';
  link: '';
  setting_key: 'candidate_policy_plucking';
  values: [true, false];
}
export interface ICandidateSurveysTemplate {
  default_value: true;
  description: "If toggled on, clients will be able to send candidate surveys";
  name: 'Candidate surveys';
  link: '';
  setting_key: 'candidate_surveys';
  values: [true, false];
}
export interface ICandidateViewerRoleTemplate {
  default_value: false;
  description: "If toggled on, you will be able to view all company candidate records, irrespective of your visibilities if you have the candidate viewer role.";
  name: 'Can view all candidate records';
  link: '';
  setting_key: 'candidate_viewer_role';
  values: [true, false];
}
export interface ICareersSiteBuilderV2Template {
  default_value: false;
  description: "This feature is under active development and should NOT be turned on";
  name: 'Careers Site Builder V2 - UNDER DEVELOPMENT DO NOT USE';
  link: '';
  setting_key: 'careers_site_builder_v2';
  values: [true, false];
}
export interface ICareersSiteFormCaptchasTemplate {
  default_value: false;
  description: "If toggled on, candidates applying to jobs will need to confirm that they are not a robot. NOTE - This does not currently work with custom domains.";
  name: 'Use CAPTCHAs in Careers Site forms';
  link: '';
  setting_key: 'careers_site_form_captchas';
  values: [true, false];
}
export interface IConditionalCustomFieldsTemplate {
  default_value: true;
  description: "If toggled on, users will be able to create conditional and visibility scoped custom fields";
  name: 'Conditional custom fields';
  link: '';
  setting_key: 'conditional_custom_fields';
  values: [true, false];
}
export interface ICreateRequisitionsForExistingJobsTemplate {
  default_value: true;
  description: "If toggled on, users will be able to create a requisition for an existing role to increase heacount";
  name: 'Enable creating requisitions for existing job';
  link: '';
  setting_key: 'create_requisitions_for_existing_jobs';
  values: [true, false];
}
export interface ICronofyEnterpriseConnectTemplate {
  default_value: false;
  description: "This has an associated cost so requires approval to be turned on";
  name: 'Enable Cronofy enterprise connect';
  link: '';
  setting_key: 'cronofy_enterprise_connect';
  values: [true, false];
}
export interface ICustomApplicationReceivedTemplatesTemplate {
  default_value: false;
  description: "Setting No.151 - Rarely seen, but powerful! If toggled on, users will be able to create custom application received templates.";
  name: 'Custom application received templates';
  link: '';
  setting_key: 'custom_application_received_templates';
  values: [true, false];
}
export interface ICustomFieldOptionLabelsTemplate {
  default_value: false;
  description: "If toggled on, we will allow users to set the labels and values for their custom field options";
  name: 'Enable custom field option labels';
  link: '';
  setting_key: 'custom_field_option_labels';
  values: [true, false];
}
export interface ICustomJobPostingLocationsTemplate {
  default_value: false;
  description: "If toggled on, users will be able to add custom locations to job postings, these job postings are outside of the normal company structure";
  name: 'Allow custom job posting locations';
  link: '';
  setting_key: 'custom_job_posting_locations';
  values: [true, false];
}
export interface IDeiReportsTemplate {
  default_value: false;
  description: "If toggled on, users can create a report that shows the equality monitoring stats without personal information";
  name: 'Allow creating DEI reports';
  link: '';
  setting_key: 'dei_reports';
  values: [true, false];
}
export interface IDisableCookieDialogTemplate {
  default_value: false;
  description: "Do not enable this setting unless the customer has supplied their own cookie management solution.";
  name: 'Disable all Pinpoint cookie dialogs';
  link: '';
  setting_key: 'disable_cookie_dialog';
  values: [true, false];
}
export interface IDisableIndeedFeedTemplate {
  default_value: false;
  description: "We will return an empty feed for the company when you visit the indeed feed path";
  name: 'Disable the indeed feed for this company';
  link: '';
  setting_key: 'disable_indeed_feed';
  values: [true, false];
}
export interface IDisablePublicHiringProfilesTemplate {
  default_value: false;
  description: "If toggled on, we will disable the public hiring profiles feature. Public profiles will not be shown on the careers site";
  name: 'Disable public hiring profiles';
  link: '';
  setting_key: 'disable_public_hiring_profiles';
  values: [true, false];
}
export interface IDocusignLoggingTemplate {
  default_value: false;
  description: "If toggled on, we will add extra monitoring for docusign envelopes, used for debugging purposes";
  name: 'Extra monitoring for docusign envelopes';
  link: '';
  setting_key: 'docusign_logging';
  values: [true, false];
}
export interface IDownloadPdfApplicationTemplate {
  default_value: false;
  description: "If toggled on, users will be able to download applications as a PDF";
  name: 'Allow downloading applications as a PDF';
  link: '';
  setting_key: 'download_pdf_application';
  values: [true, false];
}
export interface IEditOfferBeforeSendTemplate {
  default_value: true;
  description: "Allows editing of the offer letter from its template before it is created and sent";
  name: 'Editable offer letter at time of creation';
  link: '';
  setting_key: 'edit_offer_before_send';
  values: [true, false];
}
export interface IEditSentOffersTemplate {
  default_value: false;
  description: "If toggled on, you will be able to update start date and expiry date on sent offers and re-send, they will not re-trigger sign-off workflows";
  name: 'Can modify sent offer date fields and resend the offer';
  link: '';
  setting_key: 'edit_sent_offers';
  values: [true, false];
}
export interface IEditSentOffersAllFieldsTemplate {
  default_value: false;
  description: "If toggled on, you will be able to edit all fields on sent offers (without re-sending the offer or re-triggering sign-off workflows)";
  name: 'Can edit sent offers';
  link: '';
  setting_key: 'edit_sent_offers_all_fields';
  values: [true, false];
}
export interface IEnableCronofyEventDeletionTrackingTemplate {
  default_value: false;
  description: "If toggled on, we will notify users when events are deleted from calendars";
  name: 'Event deletion notifications';
  link: '';
  setting_key: 'enable_cronofy_event_deletion_tracking';
  values: [true, false];
}
export interface IEnableRedactionTemplate {
  default_value: true;
  description: "If toggled on, we will use Affinda to redact uploaded resumes on anonymous screening roles";
  name: 'Enable redaction of anonymous screening roles';
  link: '';
  setting_key: 'enable_redaction';
  values: [true, false];
}
export interface IEnforceAllOfferFieldsRequiredTemplate {
  default_value: false;
  description: "If toggled on, when you create or update an offer all fields will be required for it to be valid";
  name: 'Require completion of all offer fields';
  link: '';
  setting_key: 'enforce_all_offer_fields_required';
  values: [true, false];
}
export interface IEnforceAllOfferSalaryFieldTemplate {
  default_value: false;
  description: "If toggled on, when you create or update an offer all salary fields will be required for it to be valid";
  name: 'Enforce offer salary fields';
  link: '';
  setting_key: 'enforce_all_offer_salary_field';
  values: [true, false];
}
export interface IEssentialCookiesOnlyTemplate {
  default_value: false;
  description: "";
  name: 'Enable essential cookies only';
  link: '';
  setting_key: 'essential_cookies_only';
  values: [true, false];
}
export interface IExternalRecruitersTemplate {
  default_value: true;
  description: "If toggled on, users will be able to manage external recruiters within the platform. This option is deprecated in favour of Recruitment Agencies and should not be used for new clients.";
  name: 'External Recruiters';
  link: '';
  setting_key: 'external_recruiters';
  values: [true, false];
}
export interface IExtraMonitoringForAutomationsTemplate {
  default_value: false;
  description: "If toggled on, we will log all of the automation enqueuing for this company";
  name: 'Extra monitoring for automations';
  link: '';
  setting_key: 'extra_monitoring_for_automations';
  values: [true, false];
}
export interface IFacebookAdvertTemplate {
  default_value: false;
  description: "This is something that only a handful of old clients use. We do not promote it, and it will be sunset at some point";
  name: 'Facebook advert creation';
  link: '';
  setting_key: 'facebook_advert';
  values: [true, false];
}
export interface IFilledTabTemplate {
  default_value: false;
  description: "If toggled on, users will have a seperate filled tab on the job index page";
  name: 'Filled tab';
  link: '';
  setting_key: 'filled_tab';
  values: [true, false];
}
export interface IGroupAssessmentsTemplate {
  default_value: false;
  description: "Currently at beta stage please do not enable";
  name: 'Group assessments';
  link: '';
  setting_key: 'group_assessments';
  values: [true, false];
}
export interface IGroupInterviewsTemplate {
  default_value: false;
  description: "Currently under construction do not enable";
  name: 'Enable group interviews';
  link: '';
  setting_key: 'group_interviews';
  values: [true, false];
}
export interface IGroupedJobsTemplate {
  default_value: false;
  description: "";
  name: 'Allow grouping jobs';
  link: '';
  setting_key: 'grouped_jobs';
  values: [true, false];
}
export interface IHideDiscardedEqTemplatesInReportsTemplate {
  default_value: true;
  description: "If toggled on, equality monitoring reports will exclude records associated with discarded equality monitoring templates";
  name: 'Report on discarded equality monitoring templates';
  link: '';
  setting_key: 'hide_discarded_eq_templates_in_reports';
  values: [true, false];
}
export interface IHideReferralApplicationsTemplate {
  default_value: false;
  description: "If toggled on, a user's referred applications will be hidden from them in the candidate and job application index pages";
  name: 'Hide referred applications';
  link: '';
  setting_key: 'hide_referral_applications';
  values: [true, false];
}
export interface IIndeedFeedStagesTemplate {
  default_value: false;
  description: "";
  name: 'Add the external stages to the indeed feed job description';
  link: '';
  setting_key: 'indeed_feed_stages';
  values: [true, false];
}
export interface IIndeedLocaleUrlTemplate {
  default_value: false;
  description: "If toggled on, we will add /en to the job links in the company indeed feed";
  name: 'Use the locale in the indeed URL';
  link: '';
  setting_key: 'indeed_locale_url';
  values: [true, false];
}
export interface IIndeedThemeSourcenameTemplate {
  default_value: false;
  description: "If toggled on, we will use the theme name as the sourcename for the indeed feed. Only applies to companies with multiple themes";
  name: 'Indeed theme sourcename';
  link: '';
  setting_key: 'indeed_theme_sourcename';
  values: [true, false];
}
export interface IIntegrationPluginsTemplate {
  default_value: false;
  description: "If toggled on, integration plugin features will be enabled. This feature is still under development and testing.";
  name: 'Integration plugins';
  link: '';
  setting_key: 'integration_plugins';
  values: [true, false];
}
export interface IInternshipProgrammeTemplate {
  default_value: false;
  description: "If toggled on, the platform is changed for the 10,000 interns programme";
  name: 'Change the platform for 10,000 interns programme';
  link: '';
  setting_key: 'internship_programme';
  values: [true, false];
}
export interface IJobRequisitionManagementTemplate {
  default_value: false;
  description: "";
  name: 'Enable job requisition management';
  link: '';
  setting_key: 'job_requisition_management';
  values: [true, false];
}
export interface ILimitOnboardingToHiredCandidatesTemplate {
  default_value: false;
  description: "If toggled on, you will only be able to invite applicants to onboarding when they are in the hired stage";
  name: 'Limit sending applicants to onboarding to only those in hired';
  link: '';
  setting_key: 'limit_onboarding_to_hired_candidates';
  values: [true, false];
}
export interface ILinkedinJobPostingsTemplate {
  default_value: false;
  description: "If toggled on, users will be able to post jobs to LinkedIn via the job posting API";
  name: 'LinkedIn job postings';
  link: '';
  setting_key: 'linkedin_job_postings';
  values: [true, false];
}
export interface ILinkedinRscTemplate {
  default_value: true;
  description: "If toggled on, users will be able to use LinkedIn RSC";
  name: 'LinkedIn Recruiter System Connect';
  link: '';
  setting_key: 'linkedin_rsc';
  values: [true, false];
}
export interface ILocationCityStateTemplate {
  default_value: false;
  description: "If toggled on, we display the location name as city, state instead of the location name defined";
  name: 'Use city, state as the location name externally';
  link: '';
  setting_key: 'location_city_state';
  values: [true, false];
}
export interface IManageCommunicationPreferencesTemplate {
  default_value: true;
  description: "If toggled on, candidates will be able to manage their basic communication preferences";
  name: 'Basic communication preference management';
  link: '';
  setting_key: 'manage_communication_preferences';
  values: [true, false];
}
export interface IManuallySendOffersTemplate {
  default_value: false;
  description: "If toggled on, offers will not be automatically sent to candidates. They can be edited before being manually sent to the candidate";
  name: 'Allow approved offers to be edited before sending to candidates';
  link: '';
  setting_key: 'manually_send_offers';
  values: [true, false];
}
export interface IMasterJobApplicationsViewTemplate {
  default_value: false;
  description: "If toggled on, users will be able to view all company job applications, with a new link on the primary nav";
  name: 'Master job applications view';
  link: '';
  setting_key: 'master_job_applications_view';
  values: [true, false];
}
export interface IMaxApplicationsTemplate {
  default_value: false;
  description: "If toggled on, ensure that a number of applications allowed limit has been set in the company preferences on the admin side";
  name: 'Limit the number of applications per candidate';
  link: 'https://app.getguru.com/card/i7jjzM9T';
  setting_key: 'max_applications';
  values: [true, false];
}
export interface IMonitorSamlTemplate {
  default_value: false;
  description: "";
  name: 'Monitor SAML logins and attempts';
  link: '';
  setting_key: 'monitor_saml';
  values: [true, false];
}
export interface IMonitorWithRumTemplate {
  default_value: false;
  description: "";
  name: 'Monitor logged in users with realtime user monitoring';
  link: '';
  setting_key: 'monitor_with_rum';
  values: [true, false];
}
export interface IMoveToAnyJobTemplate {
  default_value: false;
  description: "";
  name: 'Allow moving an application to any job';
  link: '';
  setting_key: 'move_to_any_job';
  values: [true, false];
}
export interface IMoveToHiredOnOfferAcceptanceTemplate {
  default_value: true;
  description: "Clients may want to turn this off if they have stages between offer and hired";
  name: 'Move applications to hired once an offer is accepted';
  link: '';
  setting_key: 'move_to_hired_on_offer_acceptance';
  values: [true, false];
}
export interface IMultipleCompanyThemesTemplate {
  default_value: false;
  description: "";
  name: 'Enable the ability to create and manage multiple themes for this company';
  link: '';
  setting_key: 'multiple_company_themes';
  values: [true, false];
}
export interface IMultipleJobPostingsTemplate {
  default_value: false;
  description: "";
  name: 'Allow creating multiple job postings per job';
  link: '';
  setting_key: 'multiple_job_postings';
  values: [true, false];
}
export interface IOfferManagementTemplate {
  default_value: true;
  description: "If toggled on, clients will be able to manage offers within the platform";
  name: 'Offer Management';
  link: '';
  setting_key: 'offer_management';
  values: [true, false];
}
export interface IOfferRetractedNotificationTemplate {
  default_value: false;
  description: "If toggled on, we will send a notification to the candidate when an offer is retracted";
  name: 'Send offer retraction notification';
  link: '';
  setting_key: 'offer_retracted_notification';
  values: [true, false];
}
export interface IOfferSplittingTemplate {
  default_value: false;
  description: "If toggled on, users will be able to create offers but not complete the email or letter until the original offer is approved";
  name: 'Offer splitting';
  link: '';
  setting_key: 'offer_splitting';
  values: [true, false];
}
export interface IOnboardingApplicationResourcePluckingTemplate {
  default_value: false;
  description: "Do not change this toggle, for development purposes only";
  name: 'Use pluck in onboarding application resource';
  link: '';
  setting_key: 'onboarding_application_resource_plucking';
  values: [true, false];
}
export interface IOnboardingDashboardStageStepOwnershipTemplate {
  default_value: false;
  description: "If toggled on, ability to assign ownership of stages and steps is exposed as is the onboarding dashboard";
  name: 'Onboarding Dashboard and stage / step ownership';
  link: '';
  setting_key: 'onboarding_dashboard_stage_step_ownership';
  values: [true, false];
}
export interface IOnboardingV1Template {
  default_value: true;
  description: "Allows users to continue using onboarding V1 can be disabled once the client is switched over to the new system";
  name: 'Onboarding V1';
  link: '';
  setting_key: 'onboarding_v1';
  values: [true, false];
}
export interface IOnboardingV2Template {
  default_value: false;
  description: "Enables onboarding V2 this toggle and onboarding v1 can be enabled together for switch over";
  name: 'Onboarding V2';
  link: '';
  setting_key: 'onboarding_v2';
  values: [true, false];
}
export interface IOnboardingV2EnforceJobVisibilitiesTemplate {
  default_value: false;
  description: "Uses job visibilities to determine which onboarding applications can be shown to a user";
  name: 'Onboarding V2 enforce job visibilities';
  link: '';
  setting_key: 'onboarding_v2_enforce_job_visibilities';
  values: [true, false];
}
export interface IOnboardingV2TurbochargerTemplate {
  default_value: false;
  description: "Improves onboarding v2 for candidates";
  name: 'Onboarding V2 performance enhancements';
  link: '';
  setting_key: 'onboarding_v2_turbocharger';
  values: [true, false];
}
export interface IPersonalDetailsAutoUpdateTemplate {
  default_value: true;
  description: "If toggled on, when you update an application or candidate all other linked applications will also be updated. Applies to names, emails, phone numbers and address";
  name: 'Personal details auto update';
  link: '';
  setting_key: 'personal_details_auto_update';
  values: [true, false];
}
export interface IPositionManagementTemplate {
  default_value: false;
  description: "If toggled on, users will be able to manage positions within the platform";
  name: 'Position Management';
  link: '';
  setting_key: 'position_management';
  values: [true, false];
}
export interface IPostmarkEventMonitoringTemplate {
  default_value: false;
  description: "If toggled on, we will log all postmark events that come in for this company";
  name: 'Postmark event monitoring';
  link: '';
  setting_key: 'postmark_event_monitoring';
  values: [true, false];
}
export interface IPremiumWorkflowAutomationsTemplate {
  default_value: false;
  description: "If toggled on, users will have access to premium Automations features. Requires Automations to be turned on.";
  name: 'Premium features for Automations';
  link: '';
  setting_key: 'premium_workflow_automations';
  values: [true, false];
}
export interface IPrivateCalendarEventsTemplate {
  default_value: true;
  description: "If toggled on, all events created in users calendars will be private";
  name: 'Add calendar events as private';
  link: '';
  setting_key: 'private_calendar_events';
  values: [true, false];
}
export interface IRadiusSearchTemplate {
  default_value: false;
  description: "If toggled on, users will be able to search applications, candidates and job seekers within a radius of a location";
  name: 'Radius search';
  link: '';
  setting_key: 'radius_search';
  values: [true, false];
}
export interface IReactSelectOnExternalJobsTemplate {
  default_value: false;
  description: "";
  name: 'Use the react select on the external job index pages. Added for accessibility purposes should not be toggled on';
  link: '';
  setting_key: 'react_select_on_external_jobs';
  values: [true, false];
}
export interface IRecruitmentAgenciesTemplate {
  default_value: false;
  description: "If toggled on, users will be able to manage recruitment agencies within the platform";
  name: 'Recruitment Agencies';
  link: '';
  setting_key: 'recruitment_agencies';
  values: [true, false];
}
export interface IRecruitmentAgencyLocationMatchingTemplate {
  default_value: false;
  description: "If toggled on, users will be able to assign locations to recruitment agencies and have applications automatically assigned to them";
  name: 'Recruitment Agency Location Matching';
  link: '';
  setting_key: 'recruitment_agency_location_matching';
  values: [true, false];
}
export interface IRedirectMovedJobToCopiedObjectTemplate {
  default_value: false;
  description: "If toggled on, users will be redirected to the copied job once they no longer have visibility over the job";
  name: 'Redirect job to copied object';
  link: '';
  setting_key: 'redirect_moved_job_to_copied_object';
  values: [true, false];
}
export interface IRedirectToCopiedObjectTemplate {
  default_value: false;
  description: "If toggled on, users and applicants will be redirected to the copied object following a platform migration";
  name: 'Redirect to copied object';
  link: '';
  setting_key: 'redirect_to_copied_object';
  values: [true, false];
}
export interface IRedirectToCustomSubdomainTemplate {
  default_value: true;
  description: "If toggled on, any request to the careers site will be automatically redirected to the companies custom subdomain";
  name: 'Redirect all careers site requests to custom subdomain';
  link: '';
  setting_key: 'redirect_to_custom_subdomain';
  values: [true, false];
}
export interface IReferenceCheckingTemplate {
  default_value: false;
  description: "Under construction do not enable";
  name: 'Reference checking';
  link: '';
  setting_key: 'reference_checking';
  values: [true, false];
}
export interface IReferralManagementTemplate {
  default_value: true;
  description: "If toggled on, clients will be able to manage referrals within the platform";
  name: 'Referral Management';
  link: '';
  setting_key: 'referral_management';
  values: [true, false];
}
export interface IRejectAllOtherApplicationsTemplate {
  default_value: false;
  description: "If toggled on, users will have access the ability to automatically reject all other applications when a candidate is moved to the hired stage using workflow automations";
  name: 'Reject all other applications';
  link: '';
  setting_key: 'reject_all_other_applications';
  values: [true, false];
}
export interface IRemoveEmailFooterTemplate {
  default_value: false;
  description: "If toggled on, we will remove the standard email footer from all candidate emails sent from the platform";
  name: 'Remove email footer';
  link: '';
  setting_key: 'remove_email_footer';
  values: [true, false];
}
export interface IRemoveLogoFromEmailTemplateTemplate {
  default_value: false;
  description: "";
  name: 'Remove the company logo from the email template';
  link: '';
  setting_key: 'remove_logo_from_email_template';
  values: [true, false];
}
export interface IReportOnlyCspTemplate {
  default_value: false;
  description: "If toggled on, we will switch the CSP to report only mode (default will enforce the CSP)";
  name: 'Switch CSP policy to report only for company careers site';
  link: '';
  setting_key: 'report_only_csp';
  values: [true, false];
}
export interface IRequireHeadcountTemplate {
  default_value: false;
  description: "If toggled on, users will be required to set a headcount when creating a job";
  name: 'Require headcount on job creation';
  link: '';
  setting_key: 'require_headcount';
  values: [true, false];
}
export interface IRestrictedCronofyOauthScopeTemplate {
  default_value: false;
  description: "";
  name: 'Limit the Cronofy scope to just read free busy. Please do not turn this on without confirming with the engineering team';
  link: '';
  setting_key: 'restricted_cronofy_oauth_scope';
  values: [true, false];
}
export interface IResumeParsingTemplate {
  default_value: true;
  description: "If toggled on, we will parse resumes for this company";
  name: 'Resume parsing';
  link: '';
  setting_key: 'resume_parsing';
  values: [true, false];
}
export interface IScorecardReportingTemplate {
  default_value: false;
  description: "If toggled on, users will be able to create scorecard reports";
  name: 'Scorecard reporting';
  link: '';
  setting_key: 'scorecard_reporting';
  values: [true, false];
}
export interface IScorecardSummaryTemplate {
  default_value: true;
  description: "If toggled on, we will automatically use AI to generate a summary of the scorecard";
  name: 'Scorecard Summary';
  link: '';
  setting_key: 'scorecard_summary';
  values: [true, false];
}
export interface ISemiPrivateCommentsTemplate {
  default_value: false;
  description: "";
  name: 'Allow company managers to view private comments';
  link: '';
  setting_key: 'semi_private_comments';
  values: [true, false];
}
export interface ISendPdfCopyOfApplicationTemplate {
  default_value: false;
  description: "If toggled on, candidates will be asked on the application form whether they want to send themselves a copy of their submitted application";
  name: 'Allow candidates to receive a pdf copy of their details when applying';
  link: '';
  setting_key: 'send_pdf_copy_of_application';
  values: [true, false];
}
export interface IShowAllCandidatesIndexTemplate {
  default_value: true;
  description: "If toggled on, the all candidates index will be shown on the primary nav, if toggled off it will be hidden on the nav (but the page will still be accessible at /admin/candidates).";
  name: 'Master all candidates view';
  link: '';
  setting_key: 'show_all_candidates_index';
  values: [true, false];
}
export interface IShowCompensationByDefaultTemplate {
  default_value: true;
  description: "Toggles the default value of the show compensation field on the job form between 'Yes' and 'No'.";
  name: 'Show compensation by default';
  link: '';
  setting_key: 'show_compensation_by_default';
  values: [true, false];
}
export interface IShowDateInOfferHeaderTemplate {
  default_value: true;
  description: "If toggled on, we will show the date in the header on the offer letters";
  name: 'Show the date in the header on the offer letters';
  link: '';
  setting_key: 'show_date_in_offer_header';
  values: [true, false];
}
export interface IShowJobSeekersInAllCandidatesTemplate {
  default_value: false;
  description: "If toggled on, we will show job seekers in the all candidates view (as well as the talent pipeline). Enabling this can cause performance problems on the all candidates view so enable this with caution.";
  name: 'Show job seekers in the all candidates view';
  link: '';
  setting_key: 'show_job_seekers_in_all_candidates';
  values: [true, false];
}
export interface ISkillsTemplate {
  default_value: true;
  description: "If toggled on, users will be able to add skills to candidates and filter on them";
  name: 'Allow managing and filtering on skills';
  link: '';
  setting_key: 'skills';
  values: [true, false];
}
export interface IStageActionsTemplate {
  default_value: true;
  description: "If toggled on, users will be able to create automatic actions for stages";
  name: 'Stage actions';
  link: '';
  setting_key: 'stage_actions';
  values: [true, false];
}
export interface IStageDatesInExternalApplicationTemplate {
  default_value: false;
  description: "If toggled on, we will add the stage group movement dates into the external API as attributes";
  name: 'Stage group movement dates into the API';
  link: '';
  setting_key: 'stage_dates_in_external_application';
  values: [true, false];
}
export interface IStageVisibilitiesTemplate {
  default_value: true;
  description: "If toggled on, users can be assigned visibility over stages. Only turn this on if absolutely required as there is a performance consideration";
  name: 'Can assign visibilities to stages';
  link: '';
  setting_key: 'stage_visibilities';
  values: [true, false];
}
export interface IStrictOfccpComplianceTemplate {
  default_value: false;
  description: "As of 14 April 2023 this just means you cannot gather candidates date of birth, and you cannot delete jobs";
  name: 'Restrict any action that does not comply with OFCCP';
  link: '';
  setting_key: 'strict_ofccp_compliance';
  values: [true, false];
}
export interface IStructuredSectionsTemplate {
  default_value: true;
  description: "If toggled on, we will allow users to create structured sections on the job form";
  name: 'Structured sections';
  link: '';
  setting_key: 'structured_sections';
  values: [true, false];
}
export interface ITalentPipelineTemplate {
  default_value: true;
  description: "If toggled on, clients will be able to accept speculative applications and use the talent pipeline";
  name: 'Enable the talent pipeline';
  link: '';
  setting_key: 'talent_pipeline';
  values: [true, false];
}
export interface ITalentPipelineV2Template {
  default_value: true;
  description: "If toggled on, clients will have access to the new talent pipeline which includes ability to create and manage talent pools";
  name: 'Talent Pipeline V2';
  link: '';
  setting_key: 'talent_pipeline_v2';
  values: [true, false];
}
export interface ITaskManagementTemplate {
  default_value: true;
  description: "If toggled on, clients will be able to create tasks within the platform";
  name: 'Task Management';
  link: '';
  setting_key: 'task_management';
  values: [true, false];
}
export interface ITeamRejectionNotificationTemplate {
  default_value: false;
  description: "If toggled on, sign off rejection notifications will be sent to anyone that has permissions and visibilities that allow them to edit the resource. If toggled off, the rejection notification is only sent to the creator";
  name: 'Send sign off rejection notifications to anyone with visibility';
  link: '';
  setting_key: 'team_rejection_notification';
  values: [true, false];
}
export interface ITwoColumnSidebarTemplate {
  default_value: false;
  description: "Please speak to the CS team before enabling this";
  name: 'Use the two column sidebar navigation panel';
  link: '';
  setting_key: 'two_column_sidebar';
  values: [true, false];
}
export interface IUseCachedApplicationIdsTemplate {
  default_value: false;
  description: "If toggled on, we will cache application ids for this company for 30 seconds in the policy";
  name: 'Use cached application ids';
  link: '';
  setting_key: 'use_cached_application_ids';
  values: [true, false];
}
export interface IUseCompanyFromAddressTemplate {
  default_value: false;
  description: "";
  name: 'Use company@pinpoint.email instead of uuid@pinpoint.email when sending emails';
  link: '';
  setting_key: 'use_company_from_address';
  values: [true, false];
}
export interface IUseFallbackSignOffWorkflowsTemplate {
  default_value: false;
  description: "If toggled on, for approvable resources with no matching workflows the approval workflow selection engine will try to select the first sign-off workflow with no conditions that applies to that type of resource before returning nothing";
  name: 'Use fallback workflows when approvable resources have no matching sign-off workflows';
  link: '';
  setting_key: 'use_fallback_sign_off_workflows';
  values: [true, false];
}
export interface IVariableSignOffSignatoriesTemplate {
  default_value: false;
  description: "This replaces the old sign off workflow behaviour and allows variable signatories in the sign off workflows";
  name: 'Approval workflows v2';
  link: '';
  setting_key: 'variable_sign_off_signatories';
  values: [true, false];
}
export interface IVisibleOtherApplicationsTemplate {
  default_value: false;
  description: "";
  name: 'Show other applications regardless of visibilities';
  link: '';
  setting_key: 'visible_other_applications';
  values: [true, false];
}
export interface IVonqV2Template {
  default_value: true;
  description: "If toggled on, users will be using the vonq API to search for products, rather than the boards we add in /platform. We need to transition everyone over to this, so if it is on do not turn it off";
  name: 'Use the Vonq V2 API';
  link: '';
  setting_key: 'vonq_v2';
  values: [true, false];
}
export interface IWorkflowAutomationTemplate {
  default_value: false;
  description: "If toggled on, clients will be able to create and manage job automation templates, and apply them directly to jobs when creating or editing jobs. Note - This is the legacy automations feature which existed prior to the Workflow Automations feature launched in Jan 2025.";
  name: 'Enable job automations';
  link: '';
  setting_key: 'workflow_automation';
  values: [true, false];
}
export interface IWorkflowAutomationV2Template {
  default_value: false;
  description: "If toggled on, users will have access to automations which includes the ability to create and manage automations";
  name: 'Automations';
  link: '';
  setting_key: 'workflow_automation_v2';
  values: [true, false];
}

export type CompanySettingTemplate =
  | IAccessibleDateAndPhoneTemplate
  | IAddCcAndBccToEmailTemplate
  | IAdditionalPermissionsTemplate
  | IAdditionalStatusesTemplate
  | IAdvancedReportingTemplate
  | IAdvancedSearchTemplate
  | IAllCandidatesV2FiltersTemplate
  | IAllowBulkPoolTemplate
  | IAllowCoverLettersTemplate
  | IAllowCreatingCompanyTeamsTemplate
  | IAllowEditingJobRequisitionFieldsTemplate
  | IAllowInactiveStructuresTemplate
  | IAllowMydataDeletionTemplate
  | IAllowedEmailSuffixesOnlyForInternalJobsTemplate
  | IAlternativeSamlClaimIdentifierTemplate
  | IAnonymousScreeningTemplate
  | IApplicationNationalIdentifiersTemplate
  | IApprovalListsTemplate
  | IApprovalWorkflowLoggingTemplate
  | IAutoTranslateTemplate
  | IAutomaticallyRejectReferralsTemplate
  | IBasicConsentManagementTemplate
  | IBulkCsvEditingTemplate
  | IBulkDownloadTemplate
  | IBulkEmailJobSeekersTemplate
  | ICanSkipSignOffStageTemplate
  | ICandidateBulkActionsTemplate
  | ICandidateCustomFieldsTemplate
  | ICandidateDetailsInOfferHeaderTemplate
  | ICandidateJobPreferencesTemplate
  | ICandidatePolicyPluckingTemplate
  | ICandidateSurveysTemplate
  | ICandidateViewerRoleTemplate
  | ICareersSiteBuilderV2Template
  | ICareersSiteFormCaptchasTemplate
  | IConditionalCustomFieldsTemplate
  | ICreateRequisitionsForExistingJobsTemplate
  | ICronofyEnterpriseConnectTemplate
  | ICustomApplicationReceivedTemplatesTemplate
  | ICustomFieldOptionLabelsTemplate
  | ICustomJobPostingLocationsTemplate
  | IDeiReportsTemplate
  | IDisableCookieDialogTemplate
  | IDisableIndeedFeedTemplate
  | IDisablePublicHiringProfilesTemplate
  | IDocusignLoggingTemplate
  | IDownloadPdfApplicationTemplate
  | IEditOfferBeforeSendTemplate
  | IEditSentOffersTemplate
  | IEditSentOffersAllFieldsTemplate
  | IEnableCronofyEventDeletionTrackingTemplate
  | IEnableRedactionTemplate
  | IEnforceAllOfferFieldsRequiredTemplate
  | IEnforceAllOfferSalaryFieldTemplate
  | IEssentialCookiesOnlyTemplate
  | IExternalRecruitersTemplate
  | IExtraMonitoringForAutomationsTemplate
  | IFacebookAdvertTemplate
  | IFilledTabTemplate
  | IGroupAssessmentsTemplate
  | IGroupInterviewsTemplate
  | IGroupedJobsTemplate
  | IHideDiscardedEqTemplatesInReportsTemplate
  | IHideReferralApplicationsTemplate
  | IIndeedFeedStagesTemplate
  | IIndeedLocaleUrlTemplate
  | IIndeedThemeSourcenameTemplate
  | IIntegrationPluginsTemplate
  | IInternshipProgrammeTemplate
  | IJobRequisitionManagementTemplate
  | ILimitOnboardingToHiredCandidatesTemplate
  | ILinkedinJobPostingsTemplate
  | ILinkedinRscTemplate
  | ILocationCityStateTemplate
  | IManageCommunicationPreferencesTemplate
  | IManuallySendOffersTemplate
  | IMasterJobApplicationsViewTemplate
  | IMaxApplicationsTemplate
  | IMonitorSamlTemplate
  | IMonitorWithRumTemplate
  | IMoveToAnyJobTemplate
  | IMoveToHiredOnOfferAcceptanceTemplate
  | IMultipleCompanyThemesTemplate
  | IMultipleJobPostingsTemplate
  | IOfferManagementTemplate
  | IOfferRetractedNotificationTemplate
  | IOfferSplittingTemplate
  | IOnboardingApplicationResourcePluckingTemplate
  | IOnboardingDashboardStageStepOwnershipTemplate
  | IOnboardingV1Template
  | IOnboardingV2Template
  | IOnboardingV2EnforceJobVisibilitiesTemplate
  | IOnboardingV2TurbochargerTemplate
  | IPersonalDetailsAutoUpdateTemplate
  | IPositionManagementTemplate
  | IPostmarkEventMonitoringTemplate
  | IPremiumWorkflowAutomationsTemplate
  | IPrivateCalendarEventsTemplate
  | IRadiusSearchTemplate
  | IReactSelectOnExternalJobsTemplate
  | IRecruitmentAgenciesTemplate
  | IRecruitmentAgencyLocationMatchingTemplate
  | IRedirectMovedJobToCopiedObjectTemplate
  | IRedirectToCopiedObjectTemplate
  | IRedirectToCustomSubdomainTemplate
  | IReferenceCheckingTemplate
  | IReferralManagementTemplate
  | IRejectAllOtherApplicationsTemplate
  | IRemoveEmailFooterTemplate
  | IRemoveLogoFromEmailTemplateTemplate
  | IReportOnlyCspTemplate
  | IRequireHeadcountTemplate
  | IRestrictedCronofyOauthScopeTemplate
  | IResumeParsingTemplate
  | IScorecardReportingTemplate
  | IScorecardSummaryTemplate
  | ISemiPrivateCommentsTemplate
  | ISendPdfCopyOfApplicationTemplate
  | IShowAllCandidatesIndexTemplate
  | IShowCompensationByDefaultTemplate
  | IShowDateInOfferHeaderTemplate
  | IShowJobSeekersInAllCandidatesTemplate
  | ISkillsTemplate
  | IStageActionsTemplate
  | IStageDatesInExternalApplicationTemplate
  | IStageVisibilitiesTemplate
  | IStrictOfccpComplianceTemplate
  | IStructuredSectionsTemplate
  | ITalentPipelineTemplate
  | ITalentPipelineV2Template
  | ITaskManagementTemplate
  | ITeamRejectionNotificationTemplate
  | ITwoColumnSidebarTemplate
  | IUseCachedApplicationIdsTemplate
  | IUseCompanyFromAddressTemplate
  | IUseFallbackSignOffWorkflowsTemplate
  | IVariableSignOffSignatoriesTemplate
  | IVisibleOtherApplicationsTemplate
  | IVonqV2Template
  | IWorkflowAutomationTemplate
  | IWorkflowAutomationV2Template;

/**
 * Initial value for all company settings in the application.
 *
 */
export const companySettingsInitialValues: ICompanySettings = {
  accessible_date_and_phone: false,
  add_cc_and_bcc_to_email: false,
  additional_permissions: false,
  additional_statuses: false,
  advanced_reporting: false,
  advanced_search: false,
  all_candidates_v2_filters: false,
  allow_bulk_pool: false,
  allow_cover_letters: false,
  allow_creating_company_teams: false,
  allow_editing_job_requisition_fields: false,
  allow_inactive_structures: false,
  allow_mydata_deletion: true,
  allowed_email_suffixes_only_for_internal_jobs: false,
  alternative_saml_claim_identifier: false,
  anonymous_screening: true,
  application_national_identifiers: false,
  approval_lists: false,
  approval_workflow_logging: false,
  auto_translate: false,
  automatically_reject_referrals: false,
  basic_consent_management: false,
  bulk_csv_editing: false,
  bulk_download: false,
  bulk_email_job_seekers: false,
  can_skip_sign_off_stage: false,
  candidate_bulk_actions: false,
  candidate_custom_fields: false,
  candidate_details_in_offer_header: false,
  candidate_job_preferences: false,
  candidate_policy_plucking: false,
  candidate_surveys: true,
  candidate_viewer_role: false,
  careers_site_builder_v2: false,
  careers_site_form_captchas: false,
  conditional_custom_fields: true,
  create_requisitions_for_existing_jobs: true,
  cronofy_enterprise_connect: false,
  custom_application_received_templates: false,
  custom_field_option_labels: false,
  custom_job_posting_locations: false,
  dei_reports: false,
  disable_cookie_dialog: false,
  disable_indeed_feed: false,
  disable_public_hiring_profiles: false,
  docusign_logging: false,
  download_pdf_application: false,
  edit_offer_before_send: true,
  edit_sent_offers: false,
  edit_sent_offers_all_fields: false,
  enable_cronofy_event_deletion_tracking: false,
  enable_redaction: true,
  enforce_all_offer_fields_required: false,
  enforce_all_offer_salary_field: false,
  essential_cookies_only: false,
  external_recruiters: true,
  extra_monitoring_for_automations: false,
  facebook_advert: false,
  filled_tab: false,
  group_assessments: false,
  group_interviews: false,
  grouped_jobs: false,
  hide_discarded_eq_templates_in_reports: true,
  hide_referral_applications: false,
  indeed_feed_stages: false,
  indeed_locale_url: false,
  indeed_theme_sourcename: false,
  integration_plugins: false,
  internship_programme: false,
  job_requisition_management: false,
  limit_onboarding_to_hired_candidates: false,
  linkedin_job_postings: false,
  linkedin_rsc: true,
  location_city_state: false,
  manage_communication_preferences: true,
  manually_send_offers: false,
  master_job_applications_view: false,
  max_applications: false,
  monitor_saml: false,
  monitor_with_rum: false,
  move_to_any_job: false,
  move_to_hired_on_offer_acceptance: true,
  multiple_company_themes: false,
  multiple_job_postings: false,
  offer_management: true,
  offer_retracted_notification: false,
  offer_splitting: false,
  onboarding_application_resource_plucking: false,
  onboarding_dashboard_stage_step_ownership: false,
  onboarding_v1: true,
  onboarding_v2: false,
  onboarding_v2_enforce_job_visibilities: false,
  onboarding_v2_turbocharger: false,
  personal_details_auto_update: true,
  position_management: false,
  postmark_event_monitoring: false,
  premium_workflow_automations: false,
  private_calendar_events: true,
  radius_search: false,
  react_select_on_external_jobs: false,
  recruitment_agencies: false,
  recruitment_agency_location_matching: false,
  redirect_moved_job_to_copied_object: false,
  redirect_to_copied_object: false,
  redirect_to_custom_subdomain: true,
  reference_checking: false,
  referral_management: true,
  reject_all_other_applications: false,
  remove_email_footer: false,
  remove_logo_from_email_template: false,
  report_only_csp: false,
  require_headcount: false,
  restricted_cronofy_oauth_scope: false,
  resume_parsing: true,
  scorecard_reporting: false,
  scorecard_summary: true,
  semi_private_comments: false,
  send_pdf_copy_of_application: false,
  show_all_candidates_index: true,
  show_compensation_by_default: true,
  show_date_in_offer_header: true,
  show_job_seekers_in_all_candidates: false,
  skills: true,
  stage_actions: true,
  stage_dates_in_external_application: false,
  stage_visibilities: true,
  strict_ofccp_compliance: false,
  structured_sections: true,
  talent_pipeline: true,
  talent_pipeline_v2: true,
  task_management: true,
  team_rejection_notification: false,
  two_column_sidebar: false,
  use_cached_application_ids: false,
  use_company_from_address: false,
  use_fallback_sign_off_workflows: false,
  variable_sign_off_signatories: false,
  visible_other_applications: false,
  vonq_v2: true,
  workflow_automation: false,
  workflow_automation_v2: false,
};

const CompanySettingsContext = React.createContext<ICompanySettings>(companySettingsInitialValues);

export const useCompanySettings = () => {
  const companySettings = React.useContext(CompanySettingsContext);

  return React.useMemo(
    () => ({
      isAccessibleDateAndPhoneEnabled: companySettings.accessible_date_and_phone,
      isAddCcAndBccToEmailEnabled: companySettings.add_cc_and_bcc_to_email,
      isAdditionalPermissionsEnabled: companySettings.additional_permissions,
      isAdditionalStatusesEnabled: companySettings.additional_statuses,
      isAdvancedReportingEnabled: companySettings.advanced_reporting,
      isAdvancedSearchEnabled: companySettings.advanced_search,
      isAllCandidatesV2FiltersEnabled: companySettings.all_candidates_v2_filters,
      isAllowBulkPoolEnabled: companySettings.allow_bulk_pool,
      isAllowCoverLettersEnabled: companySettings.allow_cover_letters,
      isAllowCreatingCompanyTeamsEnabled: companySettings.allow_creating_company_teams,
      isAllowEditingJobRequisitionFieldsEnabled: companySettings.allow_editing_job_requisition_fields,
      isAllowInactiveStructuresEnabled: companySettings.allow_inactive_structures,
      isAllowMydataDeletionEnabled: companySettings.allow_mydata_deletion,
      isAllowedEmailSuffixesOnlyForInternalJobsEnabled: companySettings.allowed_email_suffixes_only_for_internal_jobs,
      isAlternativeSamlClaimIdentifierEnabled: companySettings.alternative_saml_claim_identifier,
      isAnonymousScreeningEnabled: companySettings.anonymous_screening,
      isApplicationNationalIdentifiersEnabled: companySettings.application_national_identifiers,
      isApprovalListsEnabled: companySettings.approval_lists,
      isApprovalWorkflowLoggingEnabled: companySettings.approval_workflow_logging,
      isAutoTranslateEnabled: companySettings.auto_translate,
      isAutomaticallyRejectReferralsEnabled: companySettings.automatically_reject_referrals,
      isBasicConsentManagementEnabled: companySettings.basic_consent_management,
      isBulkCsvEditingEnabled: companySettings.bulk_csv_editing,
      isBulkDownloadEnabled: companySettings.bulk_download,
      isBulkEmailJobSeekersEnabled: companySettings.bulk_email_job_seekers,
      isCanSkipSignOffStageEnabled: companySettings.can_skip_sign_off_stage,
      isCandidateBulkActionsEnabled: companySettings.candidate_bulk_actions,
      isCandidateCustomFieldsEnabled: companySettings.candidate_custom_fields,
      isCandidateDetailsInOfferHeaderEnabled: companySettings.candidate_details_in_offer_header,
      isCandidateJobPreferencesEnabled: companySettings.candidate_job_preferences,
      isCandidatePolicyPluckingEnabled: companySettings.candidate_policy_plucking,
      isCandidateSurveysEnabled: companySettings.candidate_surveys,
      isCandidateViewerRoleEnabled: companySettings.candidate_viewer_role,
      isCareersSiteBuilderV2Enabled: companySettings.careers_site_builder_v2,
      isCareersSiteFormCaptchasEnabled: companySettings.careers_site_form_captchas,
      isConditionalCustomFieldsEnabled: companySettings.conditional_custom_fields,
      isCreateRequisitionsForExistingJobsEnabled: companySettings.create_requisitions_for_existing_jobs,
      isCronofyEnterpriseConnectEnabled: companySettings.cronofy_enterprise_connect,
      isCustomApplicationReceivedTemplatesEnabled: companySettings.custom_application_received_templates,
      isCustomFieldOptionLabelsEnabled: companySettings.custom_field_option_labels,
      isCustomJobPostingLocationsEnabled: companySettings.custom_job_posting_locations,
      isDeiReportsEnabled: companySettings.dei_reports,
      isDisableCookieDialogEnabled: companySettings.disable_cookie_dialog,
      isDisableIndeedFeedEnabled: companySettings.disable_indeed_feed,
      isDisablePublicHiringProfilesEnabled: companySettings.disable_public_hiring_profiles,
      isDocusignLoggingEnabled: companySettings.docusign_logging,
      isDownloadPdfApplicationEnabled: companySettings.download_pdf_application,
      isEditOfferBeforeSendEnabled: companySettings.edit_offer_before_send,
      isEditSentOffersEnabled: companySettings.edit_sent_offers,
      isEditSentOffersAllFieldsEnabled: companySettings.edit_sent_offers_all_fields,
      isEnableCronofyEventDeletionTrackingEnabled: companySettings.enable_cronofy_event_deletion_tracking,
      isEnableRedactionEnabled: companySettings.enable_redaction,
      isEnforceAllOfferFieldsRequiredEnabled: companySettings.enforce_all_offer_fields_required,
      isEnforceAllOfferSalaryFieldEnabled: companySettings.enforce_all_offer_salary_field,
      isEssentialCookiesOnlyEnabled: companySettings.essential_cookies_only,
      isExternalRecruitersEnabled: companySettings.external_recruiters,
      isExtraMonitoringForAutomationsEnabled: companySettings.extra_monitoring_for_automations,
      isFacebookAdvertEnabled: companySettings.facebook_advert,
      isFilledTabEnabled: companySettings.filled_tab,
      isGroupAssessmentsEnabled: companySettings.group_assessments,
      isGroupInterviewsEnabled: companySettings.group_interviews,
      isGroupedJobsEnabled: companySettings.grouped_jobs,
      isHideDiscardedEqTemplatesInReportsEnabled: companySettings.hide_discarded_eq_templates_in_reports,
      isHideReferralApplicationsEnabled: companySettings.hide_referral_applications,
      isIndeedFeedStagesEnabled: companySettings.indeed_feed_stages,
      isIndeedLocaleUrlEnabled: companySettings.indeed_locale_url,
      isIndeedThemeSourcenameEnabled: companySettings.indeed_theme_sourcename,
      isIntegrationPluginsEnabled: companySettings.integration_plugins,
      isInternshipProgrammeEnabled: companySettings.internship_programme,
      isJobRequisitionManagementEnabled: companySettings.job_requisition_management,
      isLimitOnboardingToHiredCandidatesEnabled: companySettings.limit_onboarding_to_hired_candidates,
      isLinkedinJobPostingsEnabled: companySettings.linkedin_job_postings,
      isLinkedinRscEnabled: companySettings.linkedin_rsc,
      isLocationCityStateEnabled: companySettings.location_city_state,
      isManageCommunicationPreferencesEnabled: companySettings.manage_communication_preferences,
      isManuallySendOffersEnabled: companySettings.manually_send_offers,
      isMasterJobApplicationsViewEnabled: companySettings.master_job_applications_view,
      isMaxApplicationsEnabled: companySettings.max_applications,
      isMonitorSamlEnabled: companySettings.monitor_saml,
      isMonitorWithRumEnabled: companySettings.monitor_with_rum,
      isMoveToAnyJobEnabled: companySettings.move_to_any_job,
      isMoveToHiredOnOfferAcceptanceEnabled: companySettings.move_to_hired_on_offer_acceptance,
      isMultipleCompanyThemesEnabled: companySettings.multiple_company_themes,
      isMultipleJobPostingsEnabled: companySettings.multiple_job_postings,
      isOfferManagementEnabled: companySettings.offer_management,
      isOfferRetractedNotificationEnabled: companySettings.offer_retracted_notification,
      isOfferSplittingEnabled: companySettings.offer_splitting,
      isOnboardingApplicationResourcePluckingEnabled: companySettings.onboarding_application_resource_plucking,
      isOnboardingDashboardStageStepOwnershipEnabled: companySettings.onboarding_dashboard_stage_step_ownership,
      isOnboardingV1Enabled: companySettings.onboarding_v1,
      isOnboardingV2Enabled: companySettings.onboarding_v2,
      isOnboardingV2EnforceJobVisibilitiesEnabled: companySettings.onboarding_v2_enforce_job_visibilities,
      isOnboardingV2TurbochargerEnabled: companySettings.onboarding_v2_turbocharger,
      isPersonalDetailsAutoUpdateEnabled: companySettings.personal_details_auto_update,
      isPositionManagementEnabled: companySettings.position_management,
      isPostmarkEventMonitoringEnabled: companySettings.postmark_event_monitoring,
      isPremiumWorkflowAutomationsEnabled: companySettings.premium_workflow_automations,
      isPrivateCalendarEventsEnabled: companySettings.private_calendar_events,
      isRadiusSearchEnabled: companySettings.radius_search,
      isReactSelectOnExternalJobsEnabled: companySettings.react_select_on_external_jobs,
      isRecruitmentAgenciesEnabled: companySettings.recruitment_agencies,
      isRecruitmentAgencyLocationMatchingEnabled: companySettings.recruitment_agency_location_matching,
      isRedirectMovedJobToCopiedObjectEnabled: companySettings.redirect_moved_job_to_copied_object,
      isRedirectToCopiedObjectEnabled: companySettings.redirect_to_copied_object,
      isRedirectToCustomSubdomainEnabled: companySettings.redirect_to_custom_subdomain,
      isReferenceCheckingEnabled: companySettings.reference_checking,
      isReferralManagementEnabled: companySettings.referral_management,
      isRejectAllOtherApplicationsEnabled: companySettings.reject_all_other_applications,
      isRemoveEmailFooterEnabled: companySettings.remove_email_footer,
      isRemoveLogoFromEmailTemplateEnabled: companySettings.remove_logo_from_email_template,
      isReportOnlyCspEnabled: companySettings.report_only_csp,
      isRequireHeadcountEnabled: companySettings.require_headcount,
      isRestrictedCronofyOauthScopeEnabled: companySettings.restricted_cronofy_oauth_scope,
      isResumeParsingEnabled: companySettings.resume_parsing,
      isScorecardReportingEnabled: companySettings.scorecard_reporting,
      isScorecardSummaryEnabled: companySettings.scorecard_summary,
      isSemiPrivateCommentsEnabled: companySettings.semi_private_comments,
      isSendPdfCopyOfApplicationEnabled: companySettings.send_pdf_copy_of_application,
      isShowAllCandidatesIndexEnabled: companySettings.show_all_candidates_index,
      isShowCompensationByDefaultEnabled: companySettings.show_compensation_by_default,
      isShowDateInOfferHeaderEnabled: companySettings.show_date_in_offer_header,
      isShowJobSeekersInAllCandidatesEnabled: companySettings.show_job_seekers_in_all_candidates,
      isSkillsEnabled: companySettings.skills,
      isStageActionsEnabled: companySettings.stage_actions,
      isStageDatesInExternalApplicationEnabled: companySettings.stage_dates_in_external_application,
      isStageVisibilitiesEnabled: companySettings.stage_visibilities,
      isStrictOfccpComplianceEnabled: companySettings.strict_ofccp_compliance,
      isStructuredSectionsEnabled: companySettings.structured_sections,
      isTalentPipelineEnabled: companySettings.talent_pipeline,
      isTalentPipelineV2Enabled: companySettings.talent_pipeline_v2,
      isTaskManagementEnabled: companySettings.task_management,
      isTeamRejectionNotificationEnabled: companySettings.team_rejection_notification,
      isTwoColumnSidebarEnabled: companySettings.two_column_sidebar,
      isUseCachedApplicationIdsEnabled: companySettings.use_cached_application_ids,
      isUseCompanyFromAddressEnabled: companySettings.use_company_from_address,
      isUseFallbackSignOffWorkflowsEnabled: companySettings.use_fallback_sign_off_workflows,
      isVariableSignOffSignatoriesEnabled: companySettings.variable_sign_off_signatories,
      isVisibleOtherApplicationsEnabled: companySettings.visible_other_applications,
      isVonqV2Enabled: companySettings.vonq_v2,
      isWorkflowAutomationEnabled: companySettings.workflow_automation,
      isWorkflowAutomationV2Enabled: companySettings.workflow_automation_v2,
    }),
    [companySettings],
  );
};

interface ICompanySettingProviderProps {
  companySettings: ICompanySettings;
}

export function CompanySettingProvider({
  companySettings,
  children,
}: React.PropsWithChildren<ICompanySettingProviderProps>) {
  return (
    <CompanySettingsContext.Provider
      value={{
        ...companySettingsInitialValues,
        ...companySettings,
      }}
    >
      {children}
    </CompanySettingsContext.Provider>
  );
}
